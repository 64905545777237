<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList">
            <i class="el-icon-office-building fs12 mr5"></i>
            <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <div class="d-flex mt20">
            <div>
                <el-input class="w200 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" :disabled="!checkedCompany.pcId"
                    @click="showAddModel">新增部门</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="departmentName" align="center" label="部门名称" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <!-- <el-button class="ml10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button> -->
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="400px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="部门名称" prop="departmentName">
                    <el-input class="w240" v-model="formData.departmentName" placeholder="请输入部门名称"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>

    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { setProjectDepartment, getProjectDepartment } from "@/api/projectAdmin";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        selectCompanyList
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                departmentName: "",
            },
            rules: {
                departmentName: {
                    required: true,
                    message: '请输入部门名称',
                    trigger: "blur",
                }
            },
            formType: "",
            updateId: 0,
            checkedCompany: {},
            loadingBtn: false,
        };
    },
    filters: {

    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getList();
    },
    methods: {
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchFun();
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId
                    };
                    let msg = "新增完成！";
                    if (this.formType === 'update') {
                        params.departmentId = this.updateId;
                        msg = "编辑完成！"
                    }
                    this.loadingBtn = true;
                    setProjectDepartment(params).then(res => {
                        this.loadingBtn = false;
                        if (res.code == 200) {
                            this.$message.success(msg);
                            this.showModel=false;
                            this.getList();
                        } else {
                            this.$message.error(err.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pcId: this.checkedCompany.pcId,
            };
            if (this.searchData.name) {
                params.departmentName = this.searchData.name
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let res = await getProjectDepartment(params)
                this.loading = false;
                const { data } = res;
                this.tableData = data;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增部门";
            this.formType = "add";
            this.formData = {
                departmentName: "",
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑部门";
            this.formType = "update";
            this.updateId = val.departmentId;
            this.formData = {
                departmentName: val.departmentName,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}
</style>